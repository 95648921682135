.swal2-container {
    z-index: 2060 !important;
}

.h-modal-icon {
    font-size: 66px;
}

.inmodal .color-line {
    border-radius: 4px 4px 0 0;
}

.modal-content {
    border-top: 4px solid #001a33 !important;
    border-radius: 20px;
    border: 0;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.2);
}

.panel.panel-fullscreen .panel-container {
    background: #fff;
}

.ng-select .ng-select-container .ng-value-container .ng-input>input {
    display: none;
}

.modal-backdrop.in {
    opacity: 0.4;
}

.modal-backdrop {
    position: fixed;
    bottom: 0;
}

.modal-dialog {
    margin-top: 80px;
}

.modal-title {
    font-size: 30px;
    font-weight: 300;
}

.modal-header {
    padding: 0px 1px;
    background: #f7f9fa;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.modal-body {
    padding: 20px 30px;
}

.modal-header small {
    color: #9d9fa2;
}

.modal-footer {
    background: #f7f9fa;
}

.hmodal-info .color-line {
    background-color: #001a33;
    width: 90%;
    background-image: linear-gradient(to left, #003366, #001a33) !important;
}

.color-line {
    background-color: #001a33;
    background-image: linear-gradient(to left, #003366, #001a33) !important;
}

.-warninghmodal .color-line {
    background: #ffb606;
}

.hmodal-success .color-line {
    background: #62cb31;
}

.hmodal-danger .color-line {
    background: #c0392b;
}

.hmodal-info h4.modal-title {
    background-color: #001a33;
    background-image: linear-gradient(to left, #003366, #001a33) !important;
}

.hmodal-warning h4.modal-title {
    color: #ffb606;
}

.hmodal-success h4.modal-title {
    color: #62cb31;
}

.hmodal-danger h4.modal-title {
    color: #c0392b;
}

.color-line {
    background: #f7f9fa;
    height: 6px;
    background-image: -webkit-linear-gradient( left, #34495e, #34495e 25%, #9b59b6 25%, #9b59b6 35%, #3498db 35%, #3498db 45%, #62cb31 45%, #62cb31 55%, #ffb606 55%, #ffb606 65%, #e67e22 65%, #e67e22 75%, #e74c3c 85%, #e74c3c 85%, #c0392b 85%, #c0392b 100%);
    background-image: -moz-linear-gradient( left, #34495e, #34495e 25%, #9b59b6 25%, #9b59b6 35%, #3498db 35%, #3498db 45%, #62cb31 45%, #62cb31 55%, #ffb606 55%, #ffb606 65%, #e67e22 65%, #e67e22 75%, #e74c3c 85%, #e74c3c 85%, #c0392b 85%, #c0392b 100%);
    background-image: -ms-linear-gradient( left, #34495e, #34495e 25%, #9b59b6 25%, #9b59b6 35%, #3498db 35%, #3498db 45%, #62cb31 45%, #62cb31 55%, #ffb606 55%, #ffb606 65%, #e67e22 65%, #e67e22 75%, #e74c3c 85%, #e74c3c 85%, #c0392b 85%, #c0392b 100%);
    background-image: linear-gradient( to right, #34495e, #34495e 25%, #9b59b6 25%, #9b59b6 35%, #3498db 35%, #3498db 45%, #62cb31 45%, #62cb31 55%, #ffb606 55%, #ffb606 65%, #e67e22 65%, #e67e22 75%, #e74c3c 85%, #e74c3c 85%, #c0392b 85%, #c0392b 100%);
    background-size: 100% 6px;
    background-position: 50% 100%;
    background-repeat: no-repeat;
}

body.modal-open {
    left: 0;
    right: 0;
    padding-right: 0 !important;
    position: absolute;
    height: initial;
}

.modal-lg,
.modal-xl {
    max-width: 100%;
}

.modal-dialog {
    width: 75%;
    top: 16vh;
    border-radius: 20px;
    min-width: 500px;
    margin: 1.75rem auto;
}

.custom-modal-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 1rem;
    border-top: 0px solid #dee2e6;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

body:not(.nav-function-top) {
    aside.page-sidebar {
        height: 100%;
    }
}


/*custom*/
