/* You can add global styles to this file, and also import other style files */

@import "../node_modules/@ng-select/ng-select/themes/default.theme.css";
@import "../node_modules/ngx-bootstrap/datepicker/bs-datepicker.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~bootstrap/dist/css/bootstrap.css";
@import "./assets/iaudit.scss";
html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
